"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.listS3Keys = listS3Keys;
const client_s3_1 = require("@aws-sdk/client-s3");
const logger_1 = require("../logger");
function listS3Keys(params) {
    return __awaiter(this, void 0, void 0, function* () {
        const { bucketName, prefix } = params;
        const s3Client = new client_s3_1.S3Client();
        const keys = [];
        try {
            let continuationToken;
            do {
                const command = new client_s3_1.ListObjectsV2Command({
                    Bucket: bucketName,
                    Prefix: prefix,
                    ContinuationToken: continuationToken,
                });
                const response = yield s3Client.send(command);
                if (response.Contents) {
                    response.Contents.forEach((object) => {
                        if (object.Key) {
                            keys.push(object.Key);
                        }
                    });
                }
                continuationToken = response.NextContinuationToken;
            } while (continuationToken);
            return keys;
        }
        catch (error) {
            logger_1.logger.error("Error listing objects in S3:", error);
            throw error;
        }
    });
}
