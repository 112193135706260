import {
  GetHamuSmartyS3AssetsInput,
  HamuSmartyS3Assets,
} from "@sstoor/ts-commons";
import Cookies from "js-cookie";
import { generateGraphQlRequestBody } from "./util";

export async function getPageAssetsS3Urls({
  input,
}: {
  input: GetHamuSmartyS3AssetsInput;
}): Promise<HamuSmartyS3Assets> {
  const token = Cookies.get("auth_token");
  if (!token) {
    throw new Error("Token not present");
  }

  const query = `
    query GetHamuSmartyS3Assets($input: GetHamuSmartyS3Assets!) {
      getHamuSmartyS3Assets(input: $input) {
        images
        videos
      }
    }
  `;

  const requestOptions = generateGraphQlRequestBody({
    query,
    input,
    headers: { authorization: token },
  });

  const response = await fetch(requestOptions.url, requestOptions.params);
  const { data, errors } = (await response.json()) as {
    data: {
      getHamuSmartyS3Assets: {
        images: string[];
        videos: string[];
      };
    };
    errors: any;
  };

  console.log(data);

  if (errors) {
    throw new Error("Error fetching s3 assets urls");
  }

  return data.getHamuSmartyS3Assets;
}
