"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROPERTIES_TABLE_NAME = void 0;
exports.propertyFromDynamo = propertyFromDynamo;
exports.propertyToDynamo = propertyToDynamo;
exports.PROPERTIES_TABLE_NAME = "Properties";
function propertyFromDynamo({ property, }) {
    return {
        source: property.Source,
        sourceId: property.SourceId,
        propertyType: property.PropertyType,
        uuid: property.UUID,
        title: property.Title,
        status: property.Status,
        assetType: property.AssetType,
        webAddress: property.WebAddress,
        lat: property.Lat,
        lng: property.Lng,
        address: property.Address,
        city: property.City,
        county: property.County,
        state: property.State,
        zipCode: property.ZipCode,
        zoning: property.Zoning,
        initalEmailSent: property.InitalEmailSent,
        primaryEmailSent: property.PrimaryEmailSent,
        lastUpdatedAt: property.LastUpdatedAt,
        createdAt: property.CreatedAt,
    };
}
function propertyToDynamo({ property, }) {
    return {
        Source: property.source,
        SourceId: property.sourceId,
        PropertyType: property.propertyType,
        UUID: property.uuid,
        Title: property.title,
        Status: property.status,
        AssetType: property.assetType,
        WebAddress: property.webAddress,
        Lat: property.lat,
        Lng: property.lng,
        Address: property.address,
        City: property.city,
        County: property.county,
        State: property.state,
        ZipCode: property.zipCode,
        Zoning: property.zoning,
        InitalEmailSent: property.initalEmailSent,
        PrimaryEmailSent: property.primaryEmailSent,
        LastUpdatedAt: property.lastUpdatedAt,
        CreatedAt: property.createdAt,
    };
}
