"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.propertiesConfig = void 0;
exports.propertiesConfig = {
    gasStations: {
        loopnet: {
            texas: {
                stateCode: "TX",
                stateName: "Texas",
            },
            oklahoma: {
                stateCode: "PK",
                stateName: "Oklahoma",
            },
        },
    },
    land: {},
};
