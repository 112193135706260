"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getObjectS3 = getObjectS3;
const client_s3_1 = require("@aws-sdk/client-s3");
const logger_1 = require("../logger");
function getObjectS3(params) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const { bucketName, key } = params;
        const s3Client = new client_s3_1.S3Client();
        try {
            const command = new client_s3_1.GetObjectCommand({ Bucket: bucketName, Key: key });
            const response = yield s3Client.send(command);
            if (response.Body === undefined) {
                throw new Error(`Could not find response body for bucket ${bucketName} and key ${key}`);
            }
            const responseBody = yield ((_a = response.Body) === null || _a === void 0 ? void 0 : _a.transformToString());
            return JSON.parse(responseBody);
        }
        catch (error) {
            logger_1.logger.error("Error reading file from S3:", error);
            throw error;
        }
    });
}
