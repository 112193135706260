"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateS3CacheControl = updateS3CacheControl;
const client_s3_1 = require("@aws-sdk/client-s3");
const logger_1 = require("../logger");
const deleteObjectS3_1 = require("./deleteObjectS3");
function updateS3CacheControl(params) {
    return __awaiter(this, void 0, void 0, function* () {
        const { bucketName, s3Keys, cachedKeySuffix } = params;
        const s3Client = new client_s3_1.S3Client();
        const updatedKeys = [];
        try {
            for (const s3Key of s3Keys) {
                const newKey = addCachedSuffix({ key: s3Key, cachedKeySuffix });
                const copyCommand = new client_s3_1.CopyObjectCommand({
                    Bucket: bucketName,
                    CopySource: `${bucketName}/${s3Key}`,
                    Key: newKey,
                    MetadataDirective: "REPLACE",
                    CacheControl: "max-age=15768000, public",
                });
                updatedKeys.push(s3Key);
                yield s3Client.send(copyCommand);
                yield (0, deleteObjectS3_1.deleteObjectS3)({ bucketName, key: s3Key });
            }
            return { updatedKeys };
        }
        catch (error) {
            logger_1.logger.error("Error updating Cache-Control for S3 objects:", error);
            throw error;
        }
    });
}
function addCachedSuffix({ key, cachedKeySuffix, }) {
    const lastDotIndex = key.lastIndexOf(".");
    if (lastDotIndex === -1) {
        return `${key}${cachedKeySuffix}`;
    }
    return `${key.slice(0, lastDotIndex)}${cachedKeySuffix}${key.slice(lastDotIndex)}`;
}
