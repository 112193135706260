"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatePresignedUrl = generatePresignedUrl;
const client_s3_1 = require("@aws-sdk/client-s3");
const s3_request_presigner_1 = require("@aws-sdk/s3-request-presigner");
function generatePresignedUrl(_a) {
    return __awaiter(this, arguments, void 0, function* ({ bucketName, key, }) {
        const s3Client = new client_s3_1.S3Client();
        const presignedUrlExpiration = 60 * 60 * 24 * 7; // 7 days
        try {
            const command = new client_s3_1.GetObjectCommand({
                Bucket: bucketName,
                Key: key,
                ResponseCacheControl: `max-age=${presignedUrlExpiration}, public`,
            });
            const url = yield (0, s3_request_presigner_1.getSignedUrl)(s3Client, command, {
                expiresIn: presignedUrlExpiration,
            });
            return url;
        }
        catch (error) {
            console.error("Error generating pre-signed URL:", error);
            throw error;
        }
    });
}
