"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyType = exports.PropertyStatus = exports.PropertySource = void 0;
var PropertySource;
(function (PropertySource) {
    PropertySource["CBRE"] = "CBRE";
    PropertySource["CREXI"] = "CREXI";
    PropertySource["DB2RE"] = "DB2RE";
    PropertySource["DU_WEST"] = "DU_WEST";
    PropertySource["WEITZMAN"] = "WEITZMAN";
    PropertySource["LAND_SEARCH"] = "LAND_SEARCH";
    PropertySource["LOOP_NET"] = "LOOP_NET";
    PropertySource["NAIRL"] = "NAIRL";
})(PropertySource || (exports.PropertySource = PropertySource = {}));
var PropertyStatus;
(function (PropertyStatus) {
    PropertyStatus["REJECTED"] = "REJECTED";
    PropertyStatus["PENDING"] = "PENDING";
    PropertyStatus["SNOOZED"] = "SNOOZED";
    PropertyStatus["RECYCLE"] = "RECYCLE";
    PropertyStatus["UNDER_CONSIDERATION"] = "UNDER_CONSIDERATION";
    PropertyStatus["UNDER_PRIORITY_CONSIDERATION"] = "UNDER_PRIORITY_CONSIDERATION";
})(PropertyStatus || (exports.PropertyStatus = PropertyStatus = {}));
var PropertyType;
(function (PropertyType) {
    PropertyType["LAND"] = "LAND";
    PropertyType["GAS_STATION"] = "GAS_STATION";
})(PropertyType || (exports.PropertyType = PropertyType = {}));
