"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProperty = getProperty;
exports.queryProperty = queryProperty;
exports.getAllProperties = getAllProperties;
const lib_dynamodb_1 = require("@aws-sdk/lib-dynamodb");
const client_1 = require("../client");
const properties_1 = require("./properties");
function getProperty(_a) {
    return __awaiter(this, arguments, void 0, function* ({ propertyKey, logger, }) {
        const property = yield queryProperty({ propertyKey, logger });
        if (property === undefined) {
            const errMsg = `Could not find property with Key: ${JSON.stringify(propertyKey)}`;
            logger.error(errMsg);
            throw new Error(errMsg);
        }
        return property;
    });
}
function queryProperty(_a) {
    return __awaiter(this, arguments, void 0, function* ({ propertyKey, logger, }) {
        try {
            const command = new lib_dynamodb_1.GetCommand({
                TableName: properties_1.PROPERTIES_TABLE_NAME,
                Key: {
                    SourceId: propertyKey.sourceId,
                    Source: propertyKey.source,
                },
            });
            const response = yield client_1.dynamoDbClient.send(command);
            const item = response.Item;
            if (!item) {
                return undefined;
            }
            return (0, properties_1.propertyFromDynamo)({ property: item });
        }
        catch (error) {
            logger.error(`Error fetching property with Key: ${JSON.stringify(propertyKey)}`, error);
            throw error;
        }
    });
}
function getAllProperties(_a) {
    return __awaiter(this, arguments, void 0, function* ({ logger, propertyType, status, }) {
        logger.info(`Fetching properties with filter propertyType as ${propertyType} and status as ${status}`);
        let allItems = [];
        let lastEvaluatedKey = undefined;
        do {
            const params = {
                TableName: properties_1.PROPERTIES_TABLE_NAME,
                ExclusiveStartKey: lastEvaluatedKey,
            };
            const filterConditions = [];
            const expressionValues = {};
            const expressionNames = {};
            if (propertyType) {
                filterConditions.push("PropertyType = :propertyType");
                expressionValues[":propertyType"] = propertyType;
            }
            if (status) {
                filterConditions.push("#status = :status");
                expressionValues[":status"] = status;
                expressionNames["#status"] = "Status";
            }
            if (filterConditions.length > 0) {
                params.FilterExpression = filterConditions.join(" AND ");
                params.ExpressionAttributeValues = expressionValues;
                if (Object.keys(expressionNames).length > 0) {
                    params.ExpressionAttributeNames = expressionNames;
                }
            }
            try {
                const command = new lib_dynamodb_1.ScanCommand(params);
                const response = yield client_1.dynamoDbClient.send(command);
                if (response.Items) {
                    allItems = allItems.concat(response.Items);
                }
                lastEvaluatedKey = response.LastEvaluatedKey;
            }
            catch (error) {
                logger.error("Error scanning properties from DynamoDB: ", error);
                throw error;
            }
        } while (lastEvaluatedKey);
        logger.info(`Fetched ${allItems.length} properties`);
        return allItems.map((item) => (0, properties_1.propertyFromDynamo)({ property: item }));
    });
}
